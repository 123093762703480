import React from 'react'
import Helmet from 'react-helmet'
import Breadcrumbs from '../components/breadcrumbs'
import DocumentiUtili from '../components/documenti-utili'
import Hero from '../components/hero'
import Layout from "../components/layout"
import { graphql } from "gatsby"


class CollaborareConNoi extends React.Component {
  
  render() { 
    const breadcrumb = [{"title":"Opportunità lavorative","link":"/opportunita-lavorative"},{"title":"Vuoi collaborare con noi ai nostri studi di ricerca"}];
    const intro = this.props.data.contentfulContenutoGenerico;
    const hero = this.props.data.hero;
    const datahero = {
      "title": "Ospedale Veterinario Universitario",
      "subtitle": "studi di ricerca",
      "img": hero.childImageSharp.fluid,
      "arealink": false 
    };

    return (
      <Layout location={this.props.location}>
        <Helmet
           title="Vuoi collaborare con noi ai nostri studi di ricerca"
           bodyAttributes={
            { class :'has-hero'}
          }
        />
        <Hero data={datahero}/>

        <div className="container">
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              {!!intro && 
                <div dangerouslySetInnerHTML={{ __html: intro.abstract.childMarkdownRemark.html }} >
                
                </div>
              }
            </div>
            <div className="col-md-4">
              {!!intro.attachment &&
                <DocumentiUtili data={intro.attachment}/>
              }
            </div>
          </div>
        </div>
        <div className="box-area-subhome">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                <img src="/images/contents/doctor_big.png" alt="" className="box-area-subhome__img" />
                <div className="box-area-subhome__title">Hai bisogno di una seconda opinione? Vuoi partecipare ad un trials clinico?</div>
                <a href="mailto:direzione.sanitaria.vet@unimi.it" title="Contattaci" className="btn-link">Contattaci</a>
              </div>
            </div>
          </div>
        </div>
  

      </Layout>
    )
  }

}

export default CollaborareConNoi;

export const query = graphql`
query CollaborareConNoiQuery{
  contentfulContenutoGenerico (contentful_id: {eq: "6XZQoERRWEkewFUyjWN9J6"}) {
  	abstract {
      childMarkdownRemark {
        html
      }
    }
    attachment {
      title
      file {
        url
      }
    }
  }
  hero: file(relativePath: {eq: "hero-veterinari.png"}) {
    childImageSharp{
      fluid(maxWidth: 1920){
        ...GatsbyImageSharpFluid_withWebp
        src
      }
    }
  }
}
`